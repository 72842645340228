import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import styles from './Attribution.module.scss';
import Filters from './Filters';
import { selectWebSDKFilterParam, selectWebSDKAttributionParam } from '../../../store/reducers/webSDK';
import { webSDKApi } from '../../../api/webSDK';
import List from './List';
import { metricOptions } from '../../../utils/webSDK';

const AttributionTab = () => {
  const { id } = useParams();
  const filterParams = useSelector(selectWebSDKFilterParam);
  const attributionParams = useSelector(selectWebSDKAttributionParam);
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(Number(searchParams.get('page')) || 1);
  const [orderBy, setOrderBy] = useState(searchParams.get('orderBy') || 'unique_visitors');
  const [order, setOrder] = useState(searchParams.get('order') !== 'false');
  const [limit, setLimit] = useState(searchParams.get('perPage') || 25);

  const {
    data,
    isLoading,
    isFetching,
  } = webSDKApi.useGetWebSDKAttributionListQuery({
    id,
    limit: +limit,
    offset: (page - 1) * (+limit),
    orderBy,
    order: order ? 'desc' : 'asc',
    ...filterParams,
    ...attributionParams,
  });

  useEffect(() => {
    setSearchParams({
      page,
      orderBy,
      order,
      perPage: limit,
      ...filterParams,
      ...attributionParams,
    });
  }, [page, orderBy, order, limit, filterParams, attributionParams, setSearchParams]);

  const title = `${metricOptions.find((elem) => elem.value === attributionParams?.metric)?.label}` || '';

  return (
    <div className={`${styles.wrapper} d-flex w-100 flex-column`}>
      <Filters />
      <List
        data={data}
        isLoading={isLoading || isFetching}
        page={page}
        setPage={setPage}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        order={order}
        setOrder={setOrder}
        limit={limit}
        setLimit={setLimit}
        title={title}
        showNewVisitorsColumn={!attributionParams.new_visitors_only}
        filterParams={filterParams}
        attributionParams={attributionParams}
      />
    </div>
  );
};

export default AttributionTab;
